body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

body._has-dialer {
  padding-bottom: 70px;
}

button {
  border: 0;
}

.impersonate .chakra-container {
  background-color: #212121;
}

.popover-arrow-help-support {
  position: relative;
  left: -15px;
  z-index: 100000;
}
